.cnpa-footer{
    text-align: center;
    border-top: solid 3px black;
    padding-bottom: 5px;
    background-color: rgb(134, 183, 255);
}

.cnpa-footer>p>a {
	text-decoration: none;
    color: black;
    
}

.cnpa-footer>p>a:visited {
	color: black;
}