@media screen and (min-width: 900px) {
    .cnpa-login{
        margin: auto;
        margin-top: 50px;
        margin-bottom: 50px;
        padding: 5vw;
        padding-top: 2vh;
        padding-bottom: 5vh;
        width: 30vw;
        border: 3px solid black;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        background-color: rgb(255, 219, 164);
    }
}

.cnpa-login-title{
    text-align: center;
    margin-bottom: 25px;
}

.cnpa-login-inputs>div{
    margin: auto;
}

.cnpa-login-inputs>div>input{
    width: 250px;
}

.cnpa-login-inputs>div{
    display: flex;
    flex-direction:column;
    flex-wrap: wrap;
    margin-bottom: 25px;
}

.cnpa-login{
    margin-top: 25px;
    margin-bottom: 25px;
    
}
.cnpa-login-buttons{
    display: flex;
    justify-content: space-around;
}

.cnpa-login-inputs{
    display: flex;
    flex-direction:column;
    flex-wrap: wrap;
    margin-bottom: 25px;
}

.cnpa-login-submit, .cnpa-login-retour, .cnpa-login-register{
    padding-left: 4vw;
    padding-right: 4vw;
    padding-top: 1vh;
    padding-bottom: 1vh;
    background-color: rgb(66, 101, 255);
    color: #ffffff;
    border-radius: 5px;
}

.cnpa-login-submit:hover>a, .cnpa-login-retour>a:visited, .cnpa-login-register>a:visited{
    color: #ffffff;
    text-decoration: none;
}

.cnpa-login-submit:hover, .cnpa-login-retour:hover, .cnpa-login-register:hover{
    background-color: rgb(32, 55, 158);
    color: #ffffff;
}

.cnpa-login-noAccount{
    margin-top: 25px;
    display: flex;
    justify-content: center;
}

.cnpa-login-noAccount>p{
    margin-right: 10px;
}