@media screen and (max-width: 900px) {
    .cnpa-sousmenu{
        flex-direction: column;
        justify-content: space-between;
    }

    .cnpa-sousmenu-right, .cnpa-sousmenu-left{
        display: flex;
    }

    .cnpa-sousmenu-left{
        border-bottom: solid 3px black;
    }

    .cnpa-sousmenu-register-button, .cnpa-sousmenu-admin-button{
        border-bottom-right-radius: 5px;
    }
}

@media screen and (min-width: 900px) {
    .cnpa-sousmenu{
        justify-content: space-between;
        border-bottom: solid 3px black;
    }

    .cnpa-sousmenu-login-button, .cnpa-sousmenu-logout-button{
        border-top-left-radius: 5px;
    }
}

.cnpa-sousmenu{
    display: flex;
    padding-top: 25px;
}

.cnpa-sousmenu-button{
    padding-left: 4vw;
    padding-right: 4vw;
    padding-top: 1vh;
    padding-bottom: 1vh;
    background-color: rgb(66, 101, 255);
    color: #ffffff;
}

.cnpa-sousmenu-button:hover{
    background-color: rgb(32, 55, 158);
    color: #ffffff;
}

.cnpa-message-erreur{
    font-size: 20px;
    text-align: center;
    padding:50px;
}

.cnpa-sousmenu-json-button{
    border-top-right-radius: 5px;
}

.cnpa-sousmenu-register-button, .cnpa-sousmenu-login-button, .cnpa-sousmenu-admin-button, .cnpa-sousmenu-logout-button{
    padding-left: 4vw;
    padding-right: 4vw;
    padding-top: 1vh;
    padding-bottom: 1vh;
    background-color: rgb(66, 101, 255);
    color: #ffffff;
}

.cnpa-sousmenu-register-button>a:visited, .cnpa-sousmenu-login-button>a:visited, .cnpa-sousmenu-admin-button>a:visited, .cnpa-sousmenu-logout-button>a:visited{
    color: #ffffff;
    text-decoration: none;
}

.cnpa-sousmenu-register-button:hover, .cnpa-sousmenu-login-button:hover, .cnpa-sousmenu-admin-button:hover, .cnpa-sousmenu-logout-button:hover{
    background-color: rgb(32, 55, 158);
    color: #ffffff;
}

.cnpa-sousmenu-admin-button{
    background-color: rgb(255, 0, 0);
}

.cnpa-sousmenu-admin-button:hover{
    background-color: rgb(163, 0, 0);
}