@media screen and (min-width: 900px) {
    .cnpa-register{
        margin: auto;
        margin-top: 50px;
        margin-bottom: 50px;
        padding: 5vw;
        padding-top: 2vh;
        padding-bottom: 5vh;
        width: 30vw;
        border: 3px solid black;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        background-color: rgb(255, 219, 164);
    }
}

.cnpa-register-title{
    text-align: center;
    margin-bottom: 25px;
}

.cnpa-register-inputs>div{
    margin: auto;
}

.cnpa-register-inputs>div>input{
    width: 250px;
}

.cnpa-register-inputs>div{
    display: flex;
    flex-direction:column;
    flex-wrap: wrap;
    margin-bottom: 25px;
}

.cnpa-register{
    margin-top: 25px;
    margin-bottom: 25px;
}

.cnpa-register-buttons{
    display: flex;
    justify-content: space-around;
}

.cnpa-register-inputs{
    display: flex;
    flex-direction:column;
    flex-wrap: wrap;
    margin-bottom: 25px;
}

.cnpa-register-submit, .cnpa-register-retour, .cnpa-register-login{
    padding-left: 4vw;
    padding-right: 4vw;
    padding-top: 1vh;
    padding-bottom: 1vh;
    background-color: rgb(66, 101, 255);
    color: #ffffff;
    border-radius: 5px;
}

.cnpa-register-submit:visited, .cnpa-register-retour>a:visited, .cnpa-register-login>a:visited{
    color: #ffffff;
    text-decoration: none;
}

.cnpa-register-submit:hover, .cnpa-register-retour:hover, .cnpa-register-login:hover{
    background-color: rgb(32, 55, 158);
    color: #ffffff;
}

.cnpa-register-alreadyAccount{
    margin-top: 25px;
    display: flex;
    justify-content: center;
}

.cnpa-register-alreadyAccount>p{
    margin-right: 10px;
}