.admin-cnpa-banner{
	text-align: center;
	padding: 32px;
	background-color: rgb(134, 183, 255);
}

.admin-cnpa-sousmenu{
    border-bottom: solid 3px black;
    background-color: rgb(134, 183, 255);
}

.admin-cnpa-sousmenu-button{
    padding-left: 4vw;
    padding-right: 4vw;
    padding-top: 1vh;
    padding-bottom: 1vh;
    background-color: rgb(66, 101, 255);
    color: #ffffff;
}

.admin-cnpa-sousmenu-button:hover{
    background-color: rgb(32, 55, 158);
}

.admin-cnpa-message-erreur{
    font-size: 20px;
    text-align: center;
    padding:50px;
}

.admin-nb-users{
    padding-left: 1vw;
    padding-right: 1vw;
    padding-top: 1vh;
    padding-bottom: 1vh;
    background-color: rgb(94, 123, 255);
    color: #ffffff;
}

.admin-cnpa-retour-div{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.admin-cnpa-retour-button{
    padding-left: 4vw;
    padding-right: 4vw;
    padding-top: 1vh;
    padding-bottom: 1vh;
    background-color: rgb(66, 101, 255);
    color: #ffffff;
    border-radius: 5px;
}

.admin-cnpa-retour-button>a:visited{
    color: #ffffff;
    text-decoration: none;
}

.admin-cnpa-retour-button:hover{
    background-color: rgb(32, 55, 158);
    color: #ffffff;
}