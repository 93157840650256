.admin-cnpa-table-content{
    padding: 5px;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.admin-cnpa-table-content>form{
    padding-top: 25px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.admin-cnpa-table-value{
    text-align: center;
    margin: 5px;
    margin-top: 25px;
    margin-bottom: 25px;
    padding: 5px;
    background-color: rgb(164, 181, 255);
}

.admin-cnpa-table-value{
    border: 1px solid;
    border-radius: 5px;
}

.admin-cnpa-table-value>tbody>tr>th{
    border: 2px solid;
    border-radius: 5px;
    background-color: rgb(255, 223, 174);
}

.admin-cnpa-table-value>tbody>tr>td{
    border: 1px solid;
    border-radius: 2px;
    background-color: blanchedalmond;
}