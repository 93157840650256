.cnpa-graph-content{
    margin: auto;
    text-align: center;
}

.cnpa-graphs{
    margin: auto;
    text-align: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.cnpa-graphs>div{
    max-width: 60vh;
    width: 90vw;
    margin: auto;
    display: flex;
    flex-direction: column;
}