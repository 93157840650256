.cnpa-menu{
    background-color: blanchedalmond;
}

.cnpa-form{
    padding-top: 25px;
    padding-bottom: 25px;
}

.cnpa-form-rows{
    display: flex;
    justify-content: space-around;
}
.cnpa-form-row{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100px;
    margin-left: auto;
    margin-right: auto;
}

.cnpa-form-row-label{
    text-align: center;
    margin-bottom: 10px;
}

.cnpa-form-buttons{
    margin-top: 25px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
.cnpa-form-buttons>button{
    width: 150px;
    height: 50px;
    margin: 5px;
    border-radius: 10px;
    background-color: rgb(134, 183, 255);
}

.cnpa-form-buttons>button:hover{
    background-color: rgb(94, 158, 255);
}